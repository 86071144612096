<div [ngSwitch]="order.status">
  <ng-template [ngSwitchCase]="OrderStatus.UNASSIGNED">
    <div class="title">{{'order.statuses.received.title'|translate}}</div>
    <div class="subtitle">{{'order.statuses.received.subtitle'|translate}}</div>
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.UPCOMING">
    <div class="title">{{'order.statuses.assigned.title'|translate}}</div>
    <div class="subtitle">{{'order.statuses.assigned.subtitle'|translate}}</div>
    <div *ngIf="order.pickupTime" class="estimated">
      <div class="grey-text">
        {{'estimated-pick-up'|translate}}
      </div>
      <div class="time">
        {{ order.pickupEstimatedTime | dateFormat: "HH:mm" }}
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.WAITING_FOR_PICKUP">
    <div class="title">{{'order.statuses.pick-up-progress.title'|translate}}</div>
    <div class="subtitle">{{'order.statuses.pick-up-progress.subtitle'|translate}}</div>
    <div *ngIf="order.pickupTime" class="estimated">
      <div class="grey-text">
        {{'estimated-pick-up'|translate}}
      </div>
      <div class="time">
        {{ order.pickupEstimatedTime | dateFormat: "HH:mm" }}
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.PICKED_UP">
    <div class="title">{{'order.statuses.picked-up.title'|translate}}</div>
    <div class="subtitle">{{'order.statuses.picked-up.subtitle'|translate}}</div>
    <div *ngIf="order.dropoffTime" class="estimated">
      <div class="grey-text">
        {{'estimated-drop-off'|translate}}
      </div>
      <div class="time">
        {{ order.dropoffEstimatedTime | dateFormat: "HH:mm" }}
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]= "OrderStatus.WAITING_FOR_DROP_OFF">
    <div class='title'>{{'order.statuses.delivery-progress.title'|translate}}</div>
    <div class='subtitle'>{{'order.statuses.delivery-progress.subtitle'|translate}}</div>
    <div *ngIf="order.dropoffTime" class="estimated">
      <div class="grey-text">
        {{'estimated-drop-off'|translate}}
      </div>
      <div class="time">
        {{ order.dropoffEstimatedTime | dateFormat: "HH:mm" }}
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.DROPPED_OFF">
    <div class="title">{{'order.statuses.dropped-off.title'|translate}}</div>
    <div class="subtitle">{{'order.statuses.dropped-off.subtitle'|translate}}</div>
    <app-button [textButton]="'rate' | translate" [color]="'white'" [borderButton]="true"></app-button>
  </ng-template>
</div>
