import { Component, Input } from '@angular/core';
import { Address } from '../../models/order';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressComponent{
@Input() address: Address | undefined;
}
