import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, Observable } from "rxjs";
import * as polyline from '@mapbox/polyline';
import * as moment from 'moment';
import { Order, OrderResponse } from "src/app/models/order";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class OrderService {

  constructor(private httpClient: HttpClient) {
  }

  getOrder(orderId: string, token: string): Observable<Order> {
    return this.httpClient.get<OrderResponse>(
      `${environment.apiUrl}/orders/${orderId}/tracking`,
      {
        headers: new HttpHeaders({
            "api-key": token,
            "app-client": "OrderTracking"
          }
        )
      }
    ).pipe(
      map(OrderService.postprocessOrderResponse)
    );
  }

  private static postprocessOrderResponse(response: OrderResponse): Order {
    const processedOrder: Order = {
      courier:{
        id: response.order.courier_tracking_data?.id,
        first_name: response.order.courier_tracking_data?.first_name,
        last_name: response.order.courier_tracking_data?.last_name,
        // image_url: 'https://rapidonkey-dev-documents.s3.eu-central-1.amazonaws.com/import_test_data/Jimmy-Webb.jpg',
        image_url: response.order.courier_tracking_data?.image_url,
        phone_number: response.order.courier_tracking_data?.phone_number,
        coordinates: response.order.courier_tracking_data?.coordinates && {
          lat: response.order.courier_tracking_data?.coordinates.lat,
          lon: response.order.courier_tracking_data?.coordinates.lon
        },
        polylines: response.order.courier_tracking_data?.polylines
      },
      status: response.order.status,
      pickupAddress: {
        street: response.order.drop_off_location.address?.street,
        description: response.order.drop_off_location.address?.description,
        country: response.order.drop_off_location.address?.country,
        county: response.order.drop_off_location.address?.county,
        city: response.order.drop_off_location.address?.city,
        zipcode: response.order.drop_off_location.address?.zipcode
      },
      dropoffAddress: {
        street: response.order.pickup_location.address?.street,
        description: response.order.pickup_location.address?.description,
        country: response.order.pickup_location.address?.country,
        county: response.order.pickup_location.address?.county,
        city: response.order.pickup_location.address?.city,
        zipcode: response.order.pickup_location.address?.zipcode
      },
      dropoffTime: response.order.drop_off_location.time_window && {
        start: moment.utc(response.order.drop_off_location.time_window.start_time).local(),
        end: moment.utc(response.order.drop_off_location.time_window.end_time).local()
      },
      pickupTime: response.order.pickup_location.time_window && {
        start: moment.utc(response.order.pickup_location.time_window.start_time).local(),
        end: moment.utc(response.order.pickup_location.time_window.end_time).local()
      },
      pickupEstimatedTime: moment.utc(response.order.pickup_location.eta).local(),
      dropoffEstimatedTime: moment.utc(response.order.drop_off_location.eta).local(),
      items: response.order.delivery_items,
      goingToPolyline: response.order.courier_tracking_data?.going_to_polyline ? polyline.decode(response.order.courier_tracking_data?.going_to_polyline, 6) : undefined
    };
    processedOrder.courierPolylines = [];
    if(response.courier_tracking?.courier_polylines) {
      response.courier_tracking?.courier_polylines.forEach((poly: any) => {
        processedOrder.courierPolylines?.push(polyline.decode(poly))
      })
    }
    if (response.order.drop_off_location.address?.coordinates) {
      processedOrder.dropoffAddress.coordinates = {
        lat: response.order.drop_off_location.address?.coordinates.lat,
        lon: response.order.drop_off_location.address?.coordinates.lon,
      }
    }

    if (response.order.pickup_location.address?.coordinates) {
      processedOrder.pickupAddress.coordinates = {
        lat: response.order.pickup_location.address?.coordinates?.lat,
        lon: response.order.pickup_location.address?.coordinates?.lon,
      }
    }

    return processedOrder;
  }
}
