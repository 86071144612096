import { Component, Input } from "@angular/core";
import { Coordinates } from "../../models/coordinates";
import { Courier } from "../../models/courier";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {

  @Input() pickupCoordinates?: Coordinates;
  @Input() dropoffCoordinates?: Coordinates;
  @Input() courier?: Courier;

  @Input()
  set goingToPolyline(value: any) {
    if (value) {
      this._goingToPolyline = {
        type: "LineString",
        coordinates: value
      }
    } else {
      this._goingToPolyline = undefined;
    }
  }
  get goingToPolyline() {
    return this._goingToPolyline;
  }
  private _goingToPolyline?: any ; // TODO GeoJSON types

}
