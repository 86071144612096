import { Component, Input } from '@angular/core';
import { Courier } from '../../models/courier';

@Component({
  selector: 'app-courier-contact-mobile',
  templateUrl: './courier-contact-mobile.component.html',
  styleUrls: ['./courier-contact-mobile.component.scss']
})
export class CourierContactMobileComponent  {
  @Input() courierDetails!: Courier;
}
