<div class="container">
  <div class="order-details">
    <app-toolbar [orderId]="orderId"></app-toolbar>
    <app-order-status [orderStatus]='order.status'></app-order-status>
    <app-order-items [items]="order.items"></app-order-items>
    <app-delivery-address [address]="order.dropoffAddress"></app-delivery-address>
  </div>
  <div class="map">
    <app-map
      [courier]="order.courier"
      [pickupCoordinates]="order.pickupAddress.coordinates"
      [dropoffCoordinates]="order.dropoffAddress.coordinates"
      [goingToPolyline]="order.goingToPolyline">
    </app-map>
  </div>
</div>

