import { Component, Input } from '@angular/core';
import { Order, OrderDeliveryStatus } from '../../models/order';

@Component({
  selector: 'app-side-component',
  templateUrl: './side-component.component.html',
  styleUrls: ['./side-component.component.scss'],
})

export class SideComponentComponent {
  OrderStatus = OrderDeliveryStatus;
  @Input() order: Order | undefined;

}
