<div class="courier-info" [class.info-mobile]="!displayPhoneNumber">
  <div *ngIf="courierDetails?.image_url; else initials">
    <img src="{{courierDetails?.image_url}}" />
  </div>
  <ng-template #initials>
    <div class="initials">
      {{courierDetails?.first_name?.substring(0, 1)}}{{courierDetails?.last_name?.substring(0, 1)}}
    </div>
  </ng-template>

  <div class="info-container">
    <div class="name">
      {{courierDetails?.first_name}} {{courierDetails?.last_name}}
    </div>
    <div *ngIf="displayPhoneNumber" class="phone">
      {{courierDetails?.phone_number}}
    </div>
  </div>
</div>
