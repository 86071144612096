import { Courier } from "./courier";
import { Moment } from "moment";
import { Coordinates } from "./coordinates";

export interface Timeframe {
  start: Moment;
  end: Moment;
}

export interface TimeWindow {
  start_time: string;
  end_time: string
}

export interface Contact {
  name: string;
  phone: string;
}

export interface Location {
  id: string;
  address?: Address;
  contact: Contact;
  details?: string;
  eta: string;
  order_id: string;
  results: {
    coordinates: Coordinates;
    date: string;
    status: OrderArrivalStatus
  },
  statur: OrderDeliveryStatus,
  time_window: TimeWindow,
  type: OrderType
}

export interface CourierPolyline {
  id: number,
  km_driven: number,
  polyline: any,
  ride_id: number,
  ride_location_ids: number[],
  timestamp: string
}
// TODO - cleanup, keep only relevant attrs
export interface OrderResponse {
  courier_tracking: {
    coordinates?: Coordinates,
    courier_polylines?: CourierPolyline[]
  },
  order: {
    id: number;
    status: OrderDeliveryStatus;
    ride_id?: number;
    client_ref?: string;
    note?: string;
    vendor_id?: string;

    non_contact_delivery?: boolean;
    drop_off_location: Location,
    pickup_location: Location,

    recipient_id?: number,
    delivery_items: DeliveryItem[];
    delivery_fail_reason?: string;

    courier_tracking_data?: Courier;
  }
}

export interface DeliveryItem {
  description: string;
  quantity: number;
}

export interface Address {
  street?: string,
  description?: string,
  country?: string,
  county?: string,
  city?: string,
  zipcode?: string,
  coordinates?: Coordinates
}

export interface Order {
  courier?: Courier,
  status: string,
  pickupAddress: Address,
  dropoffAddress: Address
  pickupTime?: Timeframe,
  pickupEstimatedTime: Moment,
  dropoffTime?: Timeframe,
  dropoffEstimatedTime: Moment,
  items: Item[],
  goingToPolyline?: Array<[number, number]>,
  orderPolyline?: Array<[number, number]>,
  courierPolylines?: Array<[number, number]>[],
}

// TODO update when we get response;
export interface Item{
  description: string,
  quantity: number
}

export enum OrderDeliveryStatus {
  UNASSIGNED = 'UNASSIGNED',
  UPCOMING = 'UPCOMING',
  FAILED = 'FAILED',
  WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP',
  PICKED_UP = 'PICKED_UP',
  WAITING_FOR_DROP_OFF = 'WAITING_FOR_DROP_OFF',
  DROPPED_OFF = 'DROPPED_OFF',
  CANCEL_ORDER = 'CANCEL_ORDER'
}

export enum OrderArrivalStatus {
  IN_TIME = 'IN_TIME',
  DELAYED = 'DELAYED',
  FAILED = 'FAILED'
}

export enum OrderType {
  DROP_OFF = 'DROP_OFF',
  PICKUP = 'PICKUP'
}