<div class="container">
  <div *ngIf="courier.image_url; else initials">
    <img src="{{courier.image_url}}" />
  </div>
  <ng-template #initials>
    <div class="initials">
      {{courier.first_name?.substring(0, 1)}}{{courier.last_name?.substring(0, 1)}}
    </div>
  </ng-template>
</div>

