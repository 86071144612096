import { Component, Input } from "@angular/core";
import { Order, OrderDeliveryStatus } from "../../models/order";

@Component({
  selector: 'app-order-progress-mobile',
  templateUrl: './order-progress-mobile.component.html',
  styleUrls: ['./order-progress-mobile.component.scss']
})
export class OrderProgressMobileComponent {
  OrderStatus = OrderDeliveryStatus;
  @Input() order!: Order;
}
