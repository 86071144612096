import { Component, Input } from "@angular/core";
import { Courier } from "../../models/courier";

@Component({
  selector: "app-courier-info",
  templateUrl: "./courier-info.component.html",
  styleUrls: ["./courier-info.component.scss"]
})
export class CourierInfoComponent {
  @Input() courierDetails?: Courier;
  @Input() displayPhoneNumber: boolean = false;
}
