  <button
    id="{{customId}}"
    class="button"
    [ngClass]="[
        isDisabled ? 'disabled' : '',
        color
      ]"
    [class.inactive-cursor]="isLoading"
    [class.border-button]='borderButton'
  >
    <span *ngIf="isLoading">
      <app-loader [visible]='true' [source]="loadingGif"></app-loader>
    </span>
    <span *ngIf="!isLoading"> {{ textButton }} </span>
  </button>

