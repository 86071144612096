import { Component, Input } from "@angular/core";

@Component({
  selector: "app-call-courier-btn",
  templateUrl: "./call-courier-btn.component.html",
  styleUrls: ["./call-courier-btn.component.scss"]
})
export class CallCourierBtnComponent {
  @Input() phoneNumber!: string;

  callNumber(phoneNumber: string) {
    window.open(`tel:${phoneNumber}`);
  }
}
