import { Component } from '@angular/core';

@Component({
  selector: 'app-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss']
})
export class ExpiredLinkComponent {

}
