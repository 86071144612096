import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent {
  @Input() orderId!: string;
  @Input() showBackButton?: boolean;

  @Output() collapseEvent = new EventEmitter
}
