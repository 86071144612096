import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../models/order';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})

export class OrderItemsComponent implements OnInit {
@Input() items: Item[] | undefined;

  ngOnInit(): void {
  }
}
