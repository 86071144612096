import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() customId: string = "";
  @Input() isLoading: boolean = false;
  @Input() isDisabled = false;
  @Input() color = "";
  @Input() textButton? = "Button";
  @Input() borderButton : boolean = false;
  @Output() click = new EventEmitter();

  loadingGif: string = "../../assets/img/Rapidonkey_Loader_Small.gif";

  constructor() { }


}
