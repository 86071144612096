import { Component, Input, OnInit } from "@angular/core";
import { OrderDeliveryStatus } from "../../models/order";

@Component({
  selector: "app-order-status",
  templateUrl: "./order-status.component.html",
  styleUrls: ["./order-status.component.scss"]
})

export class OrderStatusComponent implements OnInit {
  @Input() orderStatus!: string;

  activeStatusIndex: number = 0;

  mappedOrderStatuses = [
    {
      title: "order.statuses.received.title",
      subtitle: "order.statuses.received.subtitle",
      id: 0,
      correspondent: OrderDeliveryStatus.UNASSIGNED
    },
    {
      title: "order.statuses.assigned.title",
      subtitle: "order.statuses.assigned.subtitle",
      id: 1,
      correspondent: OrderDeliveryStatus.UPCOMING
    },
    {
      title: "order.statuses.pick-up-progress.title",
      subtitle: "order.statuses.pick-up-progress.subtitle",
      id: 2,
      correspondent: OrderDeliveryStatus.WAITING_FOR_PICKUP
    },
    {
      title: "order.statuses.picked-up.title",
      subtitle: "order.statuses.picked-up.subtitle",
      id: 3,
      correspondent: OrderDeliveryStatus.PICKED_UP
    },
    {
      title: "order.statuses.delivery-progress.title",
      subtitle: "order.statuses.delivery-progress.subtitle",
      id: 4,
      correspondent: OrderDeliveryStatus.WAITING_FOR_DROP_OFF
    },
    {
      title: "order.statuses.dropped-off.title",
      subtitle: "order.statuses.dropped-off.subtitle",
      id: 5,
      correspondent: OrderDeliveryStatus.DROPPED_OFF
    }
  ];

  ngOnInit(): void {
    this.activeStatusIndex = this.mappedOrderStatuses.findIndex(status => status.correspondent === this.orderStatus);
  }
}
