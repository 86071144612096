import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from "src/app/pages/homepage/homepage.component";
import { ExpiredLinkComponent } from "src/app/pages/expired-link/expired-link.component";

const routes: Routes = [
  {
    path: "order/:id",
    component: HomepageComponent,
  },
  {
    path: "expired",
    component: ExpiredLinkComponent
  },
  {
    path: '**',
    redirectTo: "expired",
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
