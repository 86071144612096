import { Component, Input } from "@angular/core";
import { Courier } from "../../models/courier";

@Component({
  selector: 'app-courier-avatar',
  templateUrl: './courier-avatar.component.html',
  styleUrls: ['./courier-avatar.component.scss']
})
export class CourierAvatarComponent {
  @Input() courier!: Courier;
}
