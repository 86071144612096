import { Component, Input } from '@angular/core';
import { Courier } from '../../models/courier';

@Component({
  selector: 'app-courier-contact',
  templateUrl: './courier-contact.component.html',
  styleUrls: ['./courier-contact.component.scss']
})

export class CourierContactComponent {
  @Input() courierDetails: Courier|undefined;
}

