<div *ngIf="address" class="delivery-address-container">
  <div class="container-title">{{'order.delivery-address'|translate}}</div>
  <div class="info-container">
    <div class="street">{{address.street}}</div>
    <div class="description">
      <span>{{address.description}}</span>
      <span>{{address.country}}, {{address.county}}, {{address.city}}, {{address.zipcode}}</span>
    </div>
  </div>
</div>
