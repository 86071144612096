<div class="container">
  <mgl-map
    [style]="'mapbox://styles/mapbox/dark-v9'"
    [zoom]="[11]"
    [center]="[23.5982, 46.772133]"
  >
    <mgl-marker [lngLat]="pickupCoordinates">
      <div class="marker pickup">
        <img src="assets/img/pickup_location.svg">
      </div>
    </mgl-marker>
    <mgl-marker [lngLat]="dropoffCoordinates">
      <div class="marker dropoff">
        <img src="assets/img/dropoff.svg">
      </div>
    </mgl-marker>

    <mgl-marker *ngIf="courier && courier.coordinates" [lngLat]="courier.coordinates">
      <div class="marker courier">
        <div *ngIf="courier.image_url; else initials">
          <img class="avatar" src="{{courier.image_url}}" />
        </div>
        <ng-template #initials>
          <div class="initials">
            {{courier.first_name?.substring(0, 1)}}{{courier.last_name?.substring(0, 1)}}
          </div>
        </ng-template>
      </div>
    </mgl-marker>
    <mgl-geojson-source id="goingToPolyline">
      <mgl-feature
        [geometry]=goingToPolyline>
      </mgl-feature>
    </mgl-geojson-source>

    <mgl-layer
      id="goingToPolylineLayer"
      type="line"
      source="goingToPolyline"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round'
      }"
      [paint]="{
        'line-color': 'green',
        'line-width': 3
      }">
    </mgl-layer>

  </mgl-map>
</div>

