import { Component, Input } from "@angular/core";
import { Order, OrderDeliveryStatus } from "../../models/order";

@Component({
  selector: 'app-main-content-mobile',
  templateUrl: './main-content-mobile.component.html',
  styleUrls: ['./main-content-mobile.component.scss']
})
export class MainContentMobileComponent {
  @Input() order!: Order;

  OrderStatus = OrderDeliveryStatus;
}
